import { SavedState } from "./reducers/defaultReducer";
import { RootStore } from "./store";

export const selectLoadStatus = (state:RootStore) => {
    if (state.tournament.loaded === undefined) { return 'idle'}
    return state.tournament.loaded
};

export const selectBooking = (state:RootStore) => state.tournament.liveBooking;

export const selectState =(state:RootStore) => state.tournament.loaded;

export const selectTeams =(state:RootStore) => state.tournament.liveBooking?.teams;

export const selectTeam = (state:RootStore, id: string) => state.tournament.liveBooking?.teams.find(t => t.uniqueId === id)

export const savedState =(state:RootStore):SavedState => { return {playersTotal: state.tournament.playerstotal , savedPlayers: state.tournament.savedplayers}}

export const selectBookingIsLocked =(state:RootStore):boolean =>
{
    if (state.tournament.liveBooking === null) return false;

    let lockedDate: Date = new Date(state.tournament.liveBooking.startTime);
    lockedDate.setHours(lockedDate.getHours() - 1);
    const now = new Date();      

    if (lockedDate < now)
    {
        return true;
    }
    return false;
}