import React from 'react';
import { useSelector } from 'react-redux';
import { selectTeams } from '../redux/selectors';
import { TeamView } from './teamView';

export const BookingView: React.FC<{ fallback: any }> = ({ fallback }) => {
  const teams = useSelector(selectTeams);
  if (teams === undefined || teams == null) {
    return (
      <div>
        {fallback}
      </div>)
  }
  const sortedTeams = teams.sort((a,b) => a.name.localeCompare(b.name, 'en', {numeric: true}));
  const teamsToView = sortedTeams.map((team) => <TeamView id={team.uniqueId} key={team.uniqueId} />)
  return (
    <div>
      {teamsToView}
    </div>)
}
