import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeletePlayer, UpdatePlayerName } from '../redux/actions';
import { ModifiedState, Player } from '../types';

export const PlayerView: React.FC<{ player: Player, index: number }> = ({ player, index }) => {
    const [name, setName] = useState<string>(player.name ?? "")
    const dispatch = useDispatch();
    
    function handleKeyPress(e:React.KeyboardEvent){
        if (e.key !== "Enter") return;

        dispatch(UpdatePlayerName(player, name));
    }

    let classSelector = "name";

    if (player.localChange === ModifiedState.Saved)
    {
        classSelector = "name saved";
    }

    return (
        <div className="player">
            <div className="count">{index + 1}.</div>
            <div className={classSelector}><input type="text" value={name} onKeyPress={handleKeyPress} onBlur={(e) => dispatch(UpdatePlayerName(player, e.target.value))} onChange={(e) => setName(e.target.value)} placeholder="Add name" maxLength={9} /></div>
            <div className="delete" onClick={() => dispatch(DeletePlayer(player))}>X</div>
        </div>
    )
}