import { Booking, Player, Team } from "../types";

export const BOOKING_LOADING = "BOOKING_LOADING";
export const BOOKING_FAIL = "BOOKING_FAIL";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";

export interface BookingLoading {
    type: typeof BOOKING_LOADING
  }
  
  export interface BookingFail {
    type: typeof BOOKING_FAIL
  }
  
  export interface BookingSuccess {
    type: typeof BOOKING_SUCCESS,
    payload: Booking
  }
  
  export type RetrieveBookingTypes = BookingLoading | BookingFail | BookingSuccess

  export const UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST";
  export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
  export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

  export interface UpdateTeamRequest {
    type: typeof UPDATE_TEAM_REQUEST
    payload: Team
  }
  export interface UpdateTeamSuccess {
    type: typeof UPDATE_TEAM_SUCCESS
    payload: Team
  }
  export interface UpdateTeamFail {
    type: typeof UPDATE_TEAM_FAIL
    payload: Team
  }

  export interface AddPlayerToTeamRequest {
    type: typeof ADD_PLAYER_REQUEST
    teamId: string
    player: Player
  }
  export interface AddPlayerToTeamSuccess {
    type: typeof ADD_PLAYER_SUCCESS
    teamId: string
    player: Player
  }
  export interface AddPlayerToTeamFail {
    type: typeof ADD_PLAYER_FAIL
    payload: Team
  }

  export type UpdateTeamTypes = UpdateTeamRequest | UpdateTeamSuccess | UpdateTeamFail | AddPlayerToTeamRequest | AddPlayerToTeamSuccess | AddPlayerToTeamFail

  export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
  export const UPDATE_PLAYER_FAIL = "UPDATE_PLAYER_FAIL";
  export const UPDATE_PLAYER_REQUEST = "UPDATE_PLAYER_REQUEST";

  export const DELETE_PLAYER_REQUEST = "DELETE_PLAYER_REQUEST";
  export const DELETE_PLAYER_SUCCESS = "DELETE_PLAYER_SUCCESS";
  export const DELETE_PLAYER_FAIL = "DELETE_PLAYER_FAIL";
  
  export const ADD_PLAYER_REQUEST = "ADD_PLAYER_REQUEST";
  export const ADD_PLAYER_SUCCESS = "ADD_PLAYER_SUCCESS";
  export const ADD_PLAYER_FAIL = "ADD_PLAYER_FAIL";

  export interface UpdatePlayerRequest {
    type: typeof UPDATE_PLAYER_REQUEST
    payload: Player
  }
  export interface UpdatePlayerSuccess {
    type: typeof UPDATE_PLAYER_SUCCESS
    payload: Player
  }
  export interface UpdatePlayerFail {
    type: typeof UPDATE_PLAYER_FAIL
    payload: Player
  }
  export interface DeletePlayerRequest {
    type: typeof DELETE_PLAYER_REQUEST
    payload: Player
  }
  export interface DeletePlayerSuccess {
    type: typeof DELETE_PLAYER_SUCCESS
    payload: Player
  }
  export interface DeletePlayerFail {
    type: typeof DELETE_PLAYER_FAIL
    payload: Player
  }

  export type UpdatePlayerTypes = UpdatePlayerRequest | UpdatePlayerSuccess | UpdatePlayerFail | DeletePlayerRequest | DeletePlayerSuccess | DeletePlayerFail
