import { Dispatch } from "redux";
import { RetrieveBookingTypes, BOOKING_LOADING, BOOKING_FAIL, BOOKING_SUCCESS, UpdateTeamTypes, UPDATE_TEAM_REQUEST, UPDATE_TEAM_SUCCESS, UPDATE_TEAM_FAIL, UpdatePlayerTypes, UPDATE_PLAYER_SUCCESS, UPDATE_PLAYER_FAIL, UPDATE_PLAYER_REQUEST, ADD_PLAYER_SUCCESS, ADD_PLAYER_FAIL, DELETE_PLAYER_SUCCESS, DELETE_PLAYER_REQUEST, DELETE_PLAYER_FAIL } from "./actionTypes";
import { Booking, Team, Player } from "../types";

export const RetrieveBooking = (id: string) => async (dispatch: Dispatch<RetrieveBookingTypes>) => {
    try {
        dispatch({ type: BOOKING_LOADING })

        // now get the booking from the API
        const response = await fetch(`/api/GetBooking/` + id);
        if (response.status !== 200) {
            dispatch({ type: BOOKING_FAIL })
            console.error(response.statusText);
            return;
        }

        const jsonData = await response.json() as Booking;
        if (jsonData !== undefined) {
            dispatch({
                type: BOOKING_SUCCESS,
                payload: jsonData
            })
        }
    } catch (error) {
        dispatch({ type: BOOKING_FAIL })
        console.error(error);
    }
}

export const UpdateTeamName = (team: Team, newName: string) => async (dispatch: Dispatch<UpdateTeamTypes>) => {
    try {
        if (newName === "") return;
        if (team.name !== newName) {
            team.name = newName;
            dispatch({
                type: UPDATE_TEAM_REQUEST,
                payload: team
            })
            // create request object
            const request = new Request(`/api/UpdateTeam/`, {
                method: 'POST',
                body: JSON.stringify(team),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            });
            // send it
            const response = await fetch(request);
            if (response.status === 200){ 
                dispatch({
                    type: UPDATE_TEAM_SUCCESS,
                    payload: team
                })
            } else {
                console.error(response.statusText)
            }

        }
    } catch (error) {
        console.error(error);dispatch({
            type: UPDATE_TEAM_FAIL,
            payload: team

        })
    }
}

export const AddPlayerToTeam = (team: Team) => async (dispatch: Dispatch<UpdateTeamTypes>) => {

    // create request object
    const request = new Request(`/api/AddPlayerToTeam/`, {
        method: 'POST',
        body: JSON.stringify(team),
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    });
    // send it
    const response = await fetch(request);
    if (response.status === 200){ 
        const jsonData = await response.json() as Player;
        dispatch({
            type: ADD_PLAYER_SUCCESS,
            teamId: team.uniqueId,
            player: jsonData
        })
    } else {
        dispatch({
            type: ADD_PLAYER_FAIL,
            payload: team
        })
        console.error(response.statusText)
    }
}

export const UpdatePlayerName = (player: Player, newName: string) => async (dispatch: Dispatch<UpdatePlayerTypes>) => {
    try {
        if (newName === "") return;
        if (player.name !== newName) {
            player.name = newName;
            dispatch({
                type: UPDATE_PLAYER_REQUEST,
                payload: player
            })
            // create request object
            const request = new Request(`/api/UpdatePlayer/`, {
                method: 'POST',
                body: JSON.stringify(player),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            });
            // send it
            const response = await fetch(request);
            if (response.status === 200){ 
                dispatch({
                    type: UPDATE_PLAYER_SUCCESS,
                    payload: player
                })
            } else {
                console.error(response.statusText)
            }

        }
    } catch (error) {
        console.error(error);dispatch({
            type: UPDATE_PLAYER_FAIL,
            payload: player

        })
    }
}

export const DeletePlayer = (player: Player) => async (dispatch: Dispatch<UpdatePlayerTypes>) => {
    try {
            dispatch({
                type: DELETE_PLAYER_REQUEST,
                payload: player
            })
               
                // create request object
                const request = new Request(`/api/DeletePlayer/`, {
                    method: 'POST',
                    body: JSON.stringify(player),
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
                // send it
                const response = await fetch(request);
            
            if (response.status === 200){ 
                dispatch({
                    type: DELETE_PLAYER_SUCCESS,
                    payload: player
                })
            } else {
                console.error(response.statusText)
            }
    } catch (error) {
        console.error(error);dispatch({
            type: DELETE_PLAYER_FAIL,
            payload: player

        })
    }
}