import { Booking, ModifiedState, Player, Team } from "../../types";
import * as actionTypes from "../actionTypes";

export interface AppState {
    liveBooking: Booking | null;
    playerstotal: number;
    savedplayers: number;
    loaded: 'idle' | 'loading' | 'succeeded' | 'failed';
}

export interface SavedState{
    playersTotal: number;
    savedPlayers: number;
}

const initialState: AppState = {
    liveBooking: null,
    playerstotal: 0,
    savedplayers: 0,
    loaded: 'idle'
}

export const retrieveBookingReducer = (state: AppState = initialState, action: actionTypes.RetrieveBookingTypes | actionTypes.UpdateTeamTypes | actionTypes.UpdatePlayerTypes): AppState => {
    var savedStatus: SavedState = {playersTotal: 0, savedPlayers:0};

    switch (action.type) {
        case actionTypes.BOOKING_LOADING:
            return {
                liveBooking: state.liveBooking,
                playerstotal: 0,
                savedplayers: 0,
                loaded: 'loading'
            }
        case actionTypes.BOOKING_FAIL:
            return {
                liveBooking: state.liveBooking,
                playerstotal: 0,
                savedplayers: 0,
                loaded: 'failed'
            }
        case actionTypes.BOOKING_SUCCESS:
            let playerCount: number = 0;
            let savedCount: number = 0;
            action.payload.teams.forEach(team => {
                playerCount += team.players.length;
                savedCount += team.players.filter((p) => p.name?.length > 0).length;
            });
            return {
                liveBooking: action.payload,
                playerstotal: playerCount,
                savedplayers: savedCount,
                loaded: 'succeeded'
            }

        case actionTypes.UPDATE_TEAM_REQUEST:
        case actionTypes.UPDATE_TEAM_FAIL:
        case actionTypes.UPDATE_TEAM_SUCCESS:
            if (state.liveBooking === null || state.liveBooking === undefined) break;

            var teamState: ModifiedState
            if (action.type === actionTypes.UPDATE_TEAM_SUCCESS){ 
                teamState = ModifiedState.Saved;
            } else {
                teamState = ModifiedState.Changed;
            }

            let updatedTeams = state.liveBooking?.teams.map(t => t.uniqueId === action.payload.uniqueId ? 
                {...t, localChange: teamState} : t);
                             
            savedStatus = checkSavedState(updatedTeams);

                return {...state, 
                    liveBooking: { ...state.liveBooking, teams: updatedTeams} ,
                    savedplayers: savedStatus.savedPlayers,
                    playerstotal: savedStatus.playersTotal           
                }        


        case actionTypes.UPDATE_PLAYER_REQUEST:
        case actionTypes.UPDATE_PLAYER_FAIL:
        case actionTypes.UPDATE_PLAYER_SUCCESS:
            if (state.liveBooking === null || state.liveBooking === undefined) break;

            var playerState: ModifiedState
            if (action.type === actionTypes.UPDATE_PLAYER_SUCCESS){ 
                playerState = ModifiedState.Saved;
            } else {
                playerState = ModifiedState.Changed;
            }

            let teamsWithUpdatedPlayers = state.liveBooking?.teams.map(t => UpdatePlayerInTeam(t, action.payload, playerState));
                             
            savedStatus = checkSavedState(teamsWithUpdatedPlayers);

                return {...state, 
                    liveBooking: { ...state.liveBooking, teams: teamsWithUpdatedPlayers},
                    savedplayers: savedStatus.savedPlayers,
                    playerstotal: savedStatus.playersTotal        
                }

        case actionTypes.DELETE_PLAYER_FAIL:
        case actionTypes.DELETE_PLAYER_REQUEST:
        case actionTypes.DELETE_PLAYER_SUCCESS:
            if (state.liveBooking === null || state.liveBooking === undefined) break;

            if (action.type === actionTypes.DELETE_PLAYER_SUCCESS){
                return {...state,
                    liveBooking: { ...state.liveBooking, teams: state.liveBooking.teams.map(t => RemovePlayerFromTeam(t, action.payload))},
                    savedplayers: savedStatus.savedPlayers,
                    playerstotal: savedStatus.playersTotal  
                }
            }
            break;


        case actionTypes.ADD_PLAYER_REQUEST:
        case actionTypes.ADD_PLAYER_SUCCESS:
            if (state.liveBooking === null || state.liveBooking === undefined) break;

            if (action.type === actionTypes.ADD_PLAYER_SUCCESS){
                // add the player to the relevant team
                return {...state,
                    liveBooking: { ...state.liveBooking, teams: state.liveBooking.teams.map(t => AddPlayerToTeam(t, action.player, action.teamId))},
                    savedplayers: savedStatus.savedPlayers,
                    playerstotal: savedStatus.playersTotal  
                }
            }

            break;

        default:
            return state;
    }
    return state;
}

const RemovePlayerFromTeam = (team: Team, player: Player): Team => {
    return {...team, players: team.players.filter(p => p.uniqueId !== player.uniqueId)};
}

const AddPlayerToTeam = (team: Team, player: Player, targetTeamId: string): Team => {
    if (team.uniqueId !== targetTeamId) return team;

    team.players.push(player)

    return team;
}

const checkSavedState = (teams: Team[]): SavedState => {
    var playerstotal = 0
    var savedplayers = 0
    var statusObj: SavedState = {playersTotal: 0, savedPlayers:0};
        
    for (var team of teams) {
      for (var player of team.players) {
        if (player.name !== '') {
          savedplayers++
        }
        playerstotal++
      }
    }
    statusObj.savedPlayers = savedplayers
    statusObj.playersTotal = playerstotal
    return statusObj
}

const UpdatePlayerInTeam = (team: Team, player: Player, newState: ModifiedState): Team => {
    let updatedPlayers = team.players.map(p => p.uniqueId === player.uniqueId ? {...p, localChange: newState} : p)

    return {...team, players: updatedPlayers}
}