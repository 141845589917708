import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddPlayerToTeam, UpdateTeamName } from '../redux/actions';
import { selectBookingIsLocked, selectTeam } from '../redux/selectors';
import { RootStore } from '../redux/store';
import { ModifiedState } from '../types';
import { PlayerView } from './playerView';

export const TeamView: React.FC<{ id: string }> = ({ id }) => {
    const team = useSelector((state:RootStore) => selectTeam(state, id))
    const bookingLocked = useSelector(selectBookingIsLocked);
    const [name, setName] = useState<string>(team?.name ?? "")
    const dispatch = useDispatch();
    
    if (team === undefined)
    {
        return (
            <div className="d-inline-flex flex-column">
            <div className="team-container">
                <div className="title">TEAM</div>
            </div>
            </div>)
    } 

    const teamPlayers = team.players.map(((player, index) => <PlayerView player={player} index={index} key={player.uniqueId} />))

    let addPlayerButton: JSX.Element = (<></>);
    if (teamPlayers.length < 6 && !bookingLocked){
        addPlayerButton = (    <div className="add-player" onClick={() => dispatch(AddPlayerToTeam(team))}>
        ADD PLAYER +
      </div>)
    }

    let classSelector = "heading";

    if (team.localChange === ModifiedState.Saved)
    {
        classSelector = "heading saved";
    }

    return (
        <div className="d-inline-flex flex-column">
        <div className="team-container">
            <div className="title">TEAM</div>
            <div className={classSelector}>
                <input type="text" onBlur={(e) => dispatch(UpdateTeamName(team, e.target.value))} value={name} onChange={(e) => setName(e.target.value)} placeholder={"TEAM NAME"} maxLength={9} />
            </div>
            <div > {teamPlayers} </div>
            {addPlayerButton}
        </div>
        </div>)
}