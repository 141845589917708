import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from 'react-router-dom';
import { Booking } from './types';
import { BookingView } from './components/bookingView';
import store from "./redux/store";
import { savedState, selectBooking, selectBookingIsLocked, selectLoadStatus } from './redux/selectors';
import { RetrieveBooking } from './redux/actions';

// media
import logo from './assets/images/logo.png';
import heading from './assets/images/heading.png';
import floral_top from './assets/images/floral_top.png';
import floral_bottom from './assets/images/floral_bottom.png';
import { SavedState } from './redux/reducers/defaultReducer';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="header">
          <div className="logo"><img src={logo} width="280" height="221" alt="Logo" /></div>
          <div className="tournament"><img src={heading} width="612" height="148" alt="Tournament heading" /></div>
        </div>

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/:id" children={<BookingForId />} />
        </Switch>
      </Router>
    </Provider>
  );
}

function Home() {
  return (
    <div className="info"><br/>Please check the link provided, it should end with a unique identifier which looks like this: <i>d5ef5290-3dd7-4bd5-9ade-a004c6df6ddf</i> <br/>
    Contact the booking team if your link is incorrect.</div>
  )
}

export const BookingInfo: React.FC<{ booking: Booking }> = ({ booking }) => {
  // booking.startTime is not converted into a Date type, it remains as a string
  // as this is the only place it's used, just do a local conversion
  const startDate: Date = new Date(booking.startTime);
  const endDate: Date = new Date(booking.endTime);

  return (
    <div className="info">
      <ul className="event-details">
        <li>Date: {startDate.toDateString()}</li>
        <li>Start time: {startDate.toLocaleTimeString('en-GB').slice(0, -3)}</li>
        <li>Oche: {booking.playAreas}</li>
        <li>Venue: {booking.venueCode}</li>
        <li>End time: {endDate.toLocaleTimeString('en-GB').slice(0, -3)}</li>
        <li>Teams: {booking.teams.length}</li>
      </ul>
    </div>)
}

const SavePopup=(saveState: SavedState, startDate:Date): JSX.Element => {  
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  }

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    hour12: true
  }
  startDate.setHours(startDate.getHours() - 1)
  const dateString = startDate.toLocaleDateString("en-GB", options);
  const timeString = (startDate.toLocaleTimeString("en-GB", timeOptions));
  return(
    <div className="modal">
      <div className="floral-top"><img src={floral_top} alt=""/></div>
      <div className="saved">ALL SAVED!</div>
      <hr />
      <div className="status">You have now saved <strong>{saveState.savedPlayers} out of {saveState.playersTotal}</strong> people.<br />
          Your set-up will be submitted at <span className="info-highlight">{timeString} on {dateString}</span><br />
          for us to prepare everything you need! You are all set.
          </div>
      <div className="floral-bottom">
        <img src={floral_bottom} alt="" />
      </div>
    </div>
  )
  }

  const FinishedPopover=(startDate:Date): JSX.Element => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }
  
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      hour12: true
    }
    startDate.setHours(startDate.getHours() - 1)
    const dateString = startDate.toLocaleDateString("en-GB", options);
    const timeString = (startDate.toLocaleTimeString("en-GB", timeOptions));
    return(
      <div className="modal">
      <div className="floral-top"><img src={floral_top} alt=""/></div>
      <div className="saved">ALL DONE!</div>
      <hr />
      <div className="status">
          Your set-up was submitted at <span className="info-highlight">{timeString} on {dateString}</span><br />
          You are all set.<br />
          Please talk to your games master in venue to make changes.
          </div>
      <div className="floral-bottom">
        <img src={floral_bottom} alt="" />
      </div>
    </div>
    )
  }

function BookingForId() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams<{ id: string }>();
  let popover:JSX.Element = (<></>);
  let saveButton:JSX.Element = (<></>);

  const booking = useSelector(selectBooking);
  const loadStatus = useSelector(selectLoadStatus);
  const saveState = useSelector(savedState);
  const bookingLocked = useSelector(selectBookingIsLocked);
  const dispatch = useDispatch();  
  const [showSaved, setShowSaved] = useState(false);

  useEffect(() => {
    if (loadStatus === 'idle' || loadStatus === undefined) {
      dispatch(RetrieveBooking(id));
    }
  }, [id, loadStatus, dispatch]);

  switch (loadStatus) {
    case undefined:
      return (
        <div className="loader">UNDEFINED</div>)

    case 'idle':
    case 'loading':
      return (
        <div className="loader">LOADING</div>)

    case 'failed':
      return (
        <div className="loader" >BOOKING DOES NOT EXIST</div>)

    case 'succeeded':
      if (booking === undefined || booking === null) {
        return (
          <div className="loader">BOOKING DOES NOT EXIST</div>)
      }
      const startDate: Date = new Date(booking.startTime);     

      if (bookingLocked)
      {
        popover = FinishedPopover(startDate);
      }
      else
      {
        saveButton = (<div className="save-container">
          <div className="save-button" onClick={() => setShowSaved(true)} >SAVE</div>
          </div>);
      }

      if (showSaved) {
        popover = SavePopup(saveState, startDate);
      }
      return (
        <div onClick={() => { if (showSaved)setShowSaved(false)}}>
          <BookingInfo booking={booking} />
          <div className="signup">
            <div className="subheading">Let's get your teams signed up!</div>
            <div>Please enter each team and player below within 9 characters</div>
          </div>
          <div className="container-fluid teams-container">
            <BookingView fallback={"Loading..."} />
          </div>
          {saveButton}
          {popover}
        </div>
      )
  }
}

export default App;
