// NOTE
// I've added comments to strings which contain date string in the API objects below.
// Because javascript is dynamic, and the JSON format does not have a definition for Date types,
// date strings recieved from an API will not be deserialised into a Date type.
// To avoid confusion I've given dates a string type, which is how they'll be deserialised.

export enum ModifiedState{
    Unchanged,
    Changed,
    Saved
}

export interface Player{
    uniqueId: string;
    name: string;

    // date string
    lastUpdated: string;

    localChange: ModifiedState;
}


export interface Team{
    uniqueId: string;
    name: string;

    // date string
    lastUpdated: string;
    players: Player[];

    localChange: ModifiedState;
}

export interface Booking{    
    uniqueId: string;
    reference: string;
    contactName: string;

    // date string
    startTime: string;

    // date string
    endTime: string;
    type: number;
    email: string;
    phone: string;
    playAreas: string;
    venueCode: string;
    storyDeclined: boolean;

    // date string
    bookingUpdated: string;
    teams: Team[];
}